/* Common Imports */

import React from "react";

/* Component Imports */

import { Skeleton, styled } from "@mui/material";

/* Styled Components */
const CardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "12px",
	width: "6rem",
	height: "6.5rem",
	padding: "0.75rem 0.5rem 0.75rem 0.5rem",
}));

const ImageDiv = styled("div")(({ theme }) => ({
	height: "3.5rem",
	width: "3.5rem",
}));

const InfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	gap: "0.5rem",
	width: "100%",
	height: "100%",
}));

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-end",
	width: "100%",
	gap: "0.5rem",
}));

const TitleAndTypeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	gap: "0rem",
	width: "100%",
}));

const BusinessProfileLoadingCard = ({ setImageBPSize }: { setImageBPSize?: Function }) => {
	const imageRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (imageRef.current && setImageBPSize !== undefined) {
			const { width, height } = imageRef.current.getBoundingClientRect();
			setImageBPSize({ width: Math.floor(width), height: Math.floor(height) });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<CardContainer source={""}>
				<InfoContainer
					sx={{
						height: "auto",
					}}
				>
					<ImageDiv>
						<Skeleton
							variant="rectangular"
							width="3.5rem"
							height="3.5rem"
							animation="wave"
							style={{
								borderRadius: "12px",
							}}
							ref={imageRef}
						/>
					</ImageDiv>

					<ContentContainer>
						<TitleAndTypeContainer>
							<Skeleton
								variant="text"
								width="70%"
								height="1.5rem"
								animation="wave"
							/>
						</TitleAndTypeContainer>
					</ContentContainer>
				</InfoContainer>
			</CardContainer>
		</React.Fragment>
	);
};

export default BusinessProfileLoadingCard;
