/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import { ObjectId } from "mongodb";

/* Component Imports */

import PersonIcon from "@mui/icons-material/Person";
import { Link, Skeleton, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { setHomeLoadingThunk } from "../../../redux-magic/thunks";

/* Styled Components */

const BusinessProfileCardLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	color: theme.palette.mode == "dark" ? "#fff" : "#000",
	"&:focus, &:hover, &:visited, &:link, &:active": {
		textDecoration: "none",
	},
}));

const CardContainer = styled("div", {
	shouldForwardProp: (prop) => prop !== "source",
})<{ source: string }>(({ theme, source }) => ({
	background: theme.palette.background.paper,
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	borderRadius: "12px",
	width: "6rem",
	height: "6.5rem",
	padding: "0.75rem 0.5rem 0.75rem 0.5rem",
}));

const Image = styled("img")(({ theme }) => ({
	height: "3.5rem",
	width: "3.5rem",
	borderRadius: "8px",
	objectFit: "cover",
}));

const ImageDiv = styled("div")(({ theme }) => ({
	height: "3.5rem",
	width: "3.5rem",
}));

const InfoContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "center",
	gap: "0.5rem",
	width: "100%",
	height: "100%",
}));

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-end",
	width: "100%",
	gap: "0.5rem",
}));

const TitleAndTypeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0rem",
	width: "100%",
}));

const ImgContainer = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: "12px",
	height: "3.5rem",
	width: "3.5rem",
	background: theme.palette.mode === "dark" ? "#303030" : "#F5F5F5",
}));

const Title = styled(Typography)(({ theme }) => ({
	color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
	fontWeight: 450,
	fontSize: "1.25rem",
	lineHeight: "1.25rem",
	textDecoration: "none",
	textAlign: "center",
	wordBreak: "break-word",
}));

const BusinessProfileCardMini = ({
	_id,
	source,
	dispatch,
	bp_carousel_width_height,
}: {
	_id: ObjectId | string;
	source?: string;
	dispatch: Function;
	bp_carousel_width_height: string;
}) => {
	const [businessData, setBusinessData] = React.useState<any>({});
	const [loading, setLoading] = React.useState(true);

	const router = useRouter();

	let initials;

	const words = businessData.title?.split(" ") || [];
	const firstName = words[0] || "";
	const lastName = words[words.length - 1] === "" ? words[words.length - 2] || "" : words[words.length - 1] || "";

	if (words.length === 1) {
		initials = firstName.substring(0, 1).toUpperCase();
	} else {
		initials = firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase();
	}

	React.useEffect(() => {
		if (_id) {
			fetch(
				process.env.PRODUCTION_API_URL +
					`business-profiles/cards/${_id}
				${bp_carousel_width_height ? `?resize=${bp_carousel_width_height?.toString()}` : ""}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "text/plain",
					},
				},
			)
				.then((res) => res.json())
				.then((data) => {
					setBusinessData(data.BusinessProfileData);
					setLoading(false);
					if (source === "carousel") {
						dispatch(
							setHomeLoadingThunk({
								loading_top_business_profiles: false,
							}),
						);
					}
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_id]);

	if (loading) {
		/* Skeleton loading state */

		return (
			<React.Fragment>
				<CardContainer source={source ? source : ""}>
					<InfoContainer
						sx={{
							height: "auto",
						}}
					>
						<ImageDiv>
							<Skeleton
								variant="rectangular"
								width="3.5rem"
								height="3.5rem"
								animation="wave"
								style={{
									borderRadius: "12px",
								}}
							/>
						</ImageDiv>

						<ContentContainer>
							<TitleAndTypeContainer>
								<Skeleton
									variant="text"
									width="70%"
									height="1.5rem"
									animation="wave"
								/>
							</TitleAndTypeContainer>
						</ContentContainer>
					</InfoContainer>
				</CardContainer>
			</React.Fragment>
		);
	} else {
		/* Card */

		return (
			<React.Fragment>
				<BusinessProfileCardLink
					id={"home_top_realtors_" + businessData.slug}
					href={`${process.env.PRODUCTION_URL || ""}loading.html?callbackUrl=${encodeURIComponent(
						`${process.env.PRODUCTION_URL || ""}${router.locale}/${businessData.business_profile_type}s/${businessData.slug}`,
					)}&locale=${router.locale || "en-IN"}`}
					rel="noopener"
					target="_self"
					referrerPolicy="no-referrer"
					title={businessData.title ? businessData.title : "Business profile"}
					sx={{
						direction: router.locale === "ar-AE" ? "rtl" : "ltr",
					}}
				>
					<CardContainer source={source ? source : ""}>
						<ImageDiv>
							{!businessData.display_picture_url?.includes("") ? (
								<Image
									alt="Display Picture"
									loading="lazy"
									referrerPolicy="no-referrer"
									height={512}
									width={512}
									src={businessData.display_picture_url}
								/>
							) : (
								<>
									{initials !== "B" ? (
										<ImgContainer>
											<Title>{initials}</Title>
										</ImgContainer>
									) : (
										<PersonIcon
											sx={{
												display: "flex",
												width: "1.5em",
												height: "1.85rem",
											}}
										/>
									)}
								</>
							)}
						</ImageDiv>
						<Typography
							variant="body1"
							sx={{
								fontSize: "0.75rem",
								fontWeight: 400,
								lineHeight: "118%",
								color: "var(--Text-New-Secondary, #666)",
							}}
						>
							{businessData.title
								? businessData.title.length > (source === "search" ? 9 : 9)
									? businessData.title.substring(0, source === "search" ? 9 : 9) + "..."
									: businessData.title
								: ""}
						</Typography>
					</CardContainer>
				</BusinessProfileCardLink>
			</React.Fragment>
		);
	}
};

export default BusinessProfileCardMini;
